import { userState } from "@/store";
import router from "@/router";

router.beforeEach((to, from, next) => {

	if (userState.isLoggedIn) {
		if (to.path.startsWith("/login") ||
			to.path.startsWith("/register")) {
			next("/");
		}
		next();
	} else {
		if (
			to.path.startsWith("/login") ||
			to.path.startsWith("/register")
		) {
			next();
		} else {
			next("/login");
		}
	}
});