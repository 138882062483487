import {
	getAnalytics, Analytics, logEvent
} from "firebase/analytics";

import firebase from "./core";


export class VAnalytics {

	analytics: Analytics | null;

	constructor() {
		if (firebase) {
			this.analytics = getAnalytics();
		}
		else {
			this.analytics = null;
		}

	}

	logEvent(name: string, msg = "", data?: any) {
		if (!this.analytics) {
			this.analytics = getAnalytics();
		}
		logEvent(this.analytics, name, { msg, data });
		if (process.env.NODE_ENV !== "production") {
			// eslint-disable-next-line
			console.log(name, msg, data);
		}
	}
}


export default new VAnalytics();