
import vItemModel from "@/data/items/vItemModel";
import { Component, Vue } from "vue-property-decorator";
import { itemsState } from "@/store";
import store from "@/data/firebase/store";
import { ImageTypeEnum } from "@/constants/enums";
const reducer = require("image-blob-reduce")();

@Component({})
export default class ItemEdit extends Vue {
	itemName = "";
	itemDesc = "";
	saving = false;
	imageFile: File | null = null;

	get imgChosen() {
		return !!this.imageFile;
	}

	get fileImg() {
		if (this.imgChosen && this.imageFile) {
			return window.URL.createObjectURL(this.imageFile);
		}
		return "";
	}

	get itemModel() {
		if (itemsState.SelectedItem) {
			this.itemName = itemsState.SelectedItem.name;
			this.itemDesc = itemsState.SelectedItem.desc;
			return new vItemModel(itemsState.SelectedItem);
		}
		return new vItemModel();
	}

	CancelClick() {
		this.$router.push("/");
	}

	async FilesChanged(fileName: string, files: File[]) {
		if (files) {
			if (files.length === 1) {
				this.imageFile = files[0];
			} else {
				this.$toast.error("Please only select one image file.");
			}
		}
	}

	async SaveClick() {
		if (!this.saving) {
			this.saving = true;
			try {
				let item = this.itemModel.item;
				let errSaving = false;

				if (
					item.name !== this.itemName ||
					item.desc !== this.itemDesc
				) {
					item.name = this.itemName;
					item.desc = this.itemDesc;
					let saved = await store.updateItemDetails(item);
					if (saved) {
						itemsState.updateItemAction(item);
					} else {
						errSaving = true;
						this.$toast.error(
							"1. Sorry, we had an error saving your updates :("
						);
					}
				}

				if (this.imageFile) {
					const newImgBlob = await reducer.toBlob(this.imageFile, {
						max: 512,
					});

					this.imageFile = new File(
						[newImgBlob],
						this.imageFile.name,
						{
							type: "image/jpeg",
							lastModified: Date.now(),
						}
					);
					const savedImg = await store.addItemImage(
						item,
						this.imageFile,
						this.imageFile.type === "image/png"
							? ImageTypeEnum.Png
							: this.imageFile.type === "image/gif"
							? ImageTypeEnum.Gif
							: ImageTypeEnum.Jpg
					);
					errSaving = errSaving || !savedImg;
				}

				if (!errSaving) {
					this.$toast.success(`Updated item ${item.name}!`);
					this.$router.push(`/items/${item.id}/view`);
				} else {
this.$toast.error(
					"3. Sorry, we had an error saving your updates :("
				);
}
			} catch (err) {
				//console.error(err);
				this.$toast.error(
					"2. Sorry, we had an error saving your updates :("
				);
			}
			this.saving = false;
		}
	}

}
