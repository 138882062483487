
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ItemCalendar extends Vue {
	@Prop()
	readonly loading!: boolean;

	get isLoading() {
		return true;
	}
}
