
import { Component, Prop, Vue } from "vue-property-decorator";
import UsageModel from "@/data/items/usageModel";

@Component({})
export default class ItemCalendar extends Vue {
	@Prop({ default: [] })
	readonly usages!: Array<UsageModel>;

	get Usages() {
		return this.usages.slice(0, 5);
	}

	get CalDateList() {
		return this.usages.map((u) => {
			return {
				dot: true,
				dates: u.data.dateUsed,
				popover: {
					label: u.DateString,
				},
				data: u,
			};
		});
	}

	ViewUsageClick(event: Event, usage: UsageModel) {
		if (usage) {
			this.$router.push(`/items/${usage.ItemId}/usages/${usage.Id}`);
		}
	}
}
