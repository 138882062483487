import { ItemLayoutType } from '@/constants/enums';
import usage from '@/data/items/usage';
import vItem from '@/data/items/vItem';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({ name: "itemsState", stateFactory: true })
export default class ItemsState extends VuexModule {
	items: Array<vItem> = [];
	usages: Map<string, Array<usage>> = new Map();
	defaultLayoutType: ItemLayoutType = ItemLayoutType.Grid;

	selectedItemIndex = -1;

	private selectedUsageItem: usage | null = null;

	get SelectedUsageItem() {
		return this.selectedUsageItem;
	}

	get SelectedUsages(): Array<usage> {

		if (this.SelectedItem) {
			if (this.usages.has(this.SelectedItem.id)) {
				return this.usages.get(this.SelectedItem.id) ?? [];
			}
		}
		return [];
	}

	get SelectedItem(): vItem | null {
		if (this.selectedItemIndex !== -1) {
			if (this.selectedItemIndex < this.items.length) {
				return this.items[this.selectedItemIndex];
			}
			else {
				return null;
			}
		}
		else {
			return null;
		}

	}

	@Mutation
	setSelectedUsage(usage: usage) {
		this.selectedUsageItem = usage;
	}

	@Mutation
	setSelectedItem(item: vItem) {
		if (item) {
			const matchingIndex = this.items.findIndex(i => i.id === item.id);
			if (matchingIndex !== -1) {
				this.selectedItemIndex = matchingIndex;
			}
		}
	}

	@Mutation
	setItems(items: Array<vItem>) {
		this.items.length = 0;
		this.items.push(...items);
	}

	@Mutation
	addItem(item: vItem) {
		this.items.push(item);
	}

	@Mutation
	removeUsage(usage: usage) {
		const itemUsages = this.usages.get(usage.itemId) ?? [];
		const usageIndex = itemUsages.findIndex(u => u.id === usage.id);
		if (usageIndex !== -1) {
			itemUsages.splice(usageIndex, 1);

		}
	}

	@Mutation
	removeItem(item: vItem) {
		const itemIndex = this.items.findIndex(i => i.id === item.id);
		if (itemIndex !== -1) {
			this.items.splice(itemIndex, 1);
		}
	}

	@Mutation
	updateItem(item: vItem) {
		const itemIndex = this.items.findIndex(i => i.id === item.id);
		if (itemIndex !== -1) {
			this.items.splice(itemIndex, 1, item);
		}
	}

	@Mutation
	setLayoutType(layoutType: ItemLayoutType) {
		this.defaultLayoutType = layoutType;
	}

	@Action({ commit: 'setSelectedUsage' })
	setSelectedUsageAction(usage: usage) {
		return usage;
	}

	@Action({ commit: 'setSelectedItem' })
	setSelectedItemAction(item: vItem) {
		return item;
	}

	@Action({ commit: 'setItems' })
	setItemsAction(items: Array<vItem>) {
		return items;
	}

	@Action({ commit: 'addItem' })
	addItemAction(item: vItem) {
		return item;
	}

	@Action({ commit: 'removeItem' })
	removeItemAction(item: vItem) {
		return item;
	}

	@Action({ commit: 'updateItem' })
	updateItemAction(item: vItem) {
		return item;
	}

	@Action({ commit: 'setLayoutType' })
	setLayoutTypeAction(layoutType: ItemLayoutType) {
		return layoutType;
	}

}