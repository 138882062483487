
import { Component, Vue } from "vue-property-decorator";
import { Bar } from "vue-chartjs/legacy";
import { itemsState } from "@/store";

import {
	Chart as ChartJS,
	Title,
	Tooltip,
	Legend,
	BarElement,
	CategoryScale,
	LinearScale,
} from "chart.js";
import UsageModel from "@/data/items/usageModel";
import vItemModel from "@/data/items/vItemModel";
import UsageItemSum from "@/data/items/usageItemSum";
import store from "@/data/firebase/store";

ChartJS.register(
	Title,
	Tooltip,
	Legend,
	BarElement,
	CategoryScale,
	LinearScale
);

ChartJS.defaults.plugins.legend.display = false;

@Component({ components: { Bar } })
export default class Dashboard extends Vue {
	usages: Array<UsageModel> = [];
	items: Array<vItemModel> = [];

	windowWidth = window.innerWidth;

	get CalDateList() {
		return this.usages.map((u) => {
			const item = this.items.find((i) => i.Id === u.data.itemId);
			return {
				dot: true,
				dates: u.data.dateUsed,
				popover: {
					label: item ? item.Name : "Unknown item",
				},
			};
		});
	}

	get chartData() {
		let itemsums: Array<UsageItemSum> = this.items.map((i) => {
			return { Name: i.Name, Id: i.Id, Uses: 0 };
		});
		itemsums.forEach((i) => {
			i.Uses = this.usages.filter((u) => u.data.itemId === i.Id).length;
		});

		itemsums = itemsums
			.filter((i) => i.Uses > 0)
			.sort((a, b) => (a.Uses > b.Uses ? -1 : 1));

		//console.log(itemsums);

		return {
			labels: itemsums.map((i) => {
				let labelArray: Array<string>;
				let labelName = i.Name.length > 24 ? i.Name.substring(0, 21) + "..." : i.Name;
				const spaceIndex = labelName.indexOf(' ', 8);
				if (labelName.length > 12 && spaceIndex !== -1) {
					labelArray = [labelName.substring(0, spaceIndex), labelName.substring(spaceIndex)]
				}
				else {
					labelArray = [labelName];
				}

				return labelArray;
			}),
			datasets: [{ data: itemsums.map((i) => i.Uses) }],
		};
	}

	get useHoriz() {
		//console.log(this.windowWidth);
		return this.windowWidth < 800;
	}

	get chartOptions() {

		if (this.useHoriz) {
			return { responsive: true, indexAxis: 'y' };
		}
		else {
			return { responsive: true };
		}

	}

	resizeHandler() {
		this.windowWidth = window.innerWidth;
	}

	get chartHeight() {
		return this.useHoriz ? 400 : 100;
	}
	async mounted() {
		this.windowWidth = window.innerWidth;

		window.addEventListener("resize", this.resizeHandler);

		let uses = await store.getLatestUsages();
		this.items = itemsState.items.map((i) => new vItemModel(i));
		this.usages = uses.map((u) => new UsageModel(u));
	}

	unmounted() {
		window.removeEventListener("resize", this.resizeHandler);
	}


}
