export enum ImageTypeEnum {
	Png,
	Jpg,
	Gif
}

export enum ItemLayoutType {
	Grid,
	List
}

export enum SortDirection {
	Asc,
	Desc
}

export enum SortType {
	Alpha,
	LastUsed,
	FreqUsed
}