import vItem from "./vItem";
import dayjs from "dayjs";

export default class vItemModel {
	item: vItem;

	get Name() {
		return this.item.name;
	}

	get LastUsed() {
		return this.item.lastUsed ?? this.item.dateAdded;
	}

	get LastUsedString() {
		return dayjs(this.LastUsed).fromNow();
	}

	get Desc() {
		return this.item.desc ?? "";
	}

	get Id() {
		return this.item.id;
	}

	get HasImage() {
		return this.item.imgUrl;
	}

	get ImageBgCss() {
		return `url("${this.Image}")`;
	}

	get Image() {
		if (this.item.imgUrl) {
			return this.item.imgUrl;
		}
		else {
			return "/img/item.png";
		}
	}

	get UsesString() {
		if (!this.item.totalUses) {
			return "";
		}
		else if (this.item.totalUses === 1) {
			return "Used once";
		}
		else if (this.item.totalUses === 2) {
			return "Used twice";
		}
		else {
			return `Used ${this.item.totalUses} times`;
		}
	}

	get Uses() {
		return this.item.totalUses;
	}

	constructor(item?: vItem) {
		if (item) {
			this.item = item;
		}
		else {
			this.item = { id: "", uid: "", name: "", desc: "", dateAdded: new Date(), lastUsed: new Date(), imgName: "", imgUrl: "", totalUses: 0 };
		}

	}
}