import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { userState } from "@/store";

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
	if (user) {
		// User is signed in, see docs for a list of available properties
		// https://firebase.google.com/docs/reference/js/firebase.User
		userState.setUidAction(user.uid);
		//console.log(uid, user);
		// ...
	} else {
		// User is signed out
		// ...
		//console.log("signed out");
		userState.setIsLoggedInAction(false);
	}
});

export default auth;