import { initializeApp } from 'firebase/app';

const firebaseConfig = {
	apiKey: "AIzaSyBjW4ScnG6t1jzD1LlJkozj0u_VKBajuWw",
	authDomain: "valorem-1f872.firebaseapp.com",
	projectId: "valorem-1f872",
	storageBucket: "valorem-1f872.appspot.com",
	messagingSenderId: "794688602728",
	appId: "1:794688602728:web:820a32fadfc65c0364b1dd",
	measurementId: "G-J434NYNK53"
};

const app = initializeApp(firebaseConfig);

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
import auth from "./auth";

export default app;