import { Firestore, getFirestore, collection, setDoc, doc, getDocs, updateDoc, query, Query, where, orderBy, limit, DocumentData, deleteDoc } from "firebase/firestore";
import fbapp from "./core";
import { userState, itemsState } from "@/store";
import { v4 as uuidv4 } from "uuid";
import vItem from "../items/vItem";
import usage from "@/data/items/usage";
import { getStorage, FirebaseStorage, ref, StorageReference, uploadBytes, getDownloadURL } from "firebase/storage";
import { ImageTypeEnum } from "@/constants/enums";
import dayjs from "dayjs";
import AddUsageReturnVal from "./types/addUsageReturnVal";
export class db {

	firestore: Firestore;
	storage: FirebaseStorage;
	imageFolderRef: StorageReference;

	constructor() {
		this.firestore = getFirestore(fbapp);
		this.storage = getStorage(fbapp);
		this.imageFolderRef = ref(this.storage, "images");
	}

	async getAllItems() {
		const items = await getDocs(collection(this.firestore, "users", userState.uid, "items"));

		if (!items.empty) {
			return items.docs.map(i => {
				const data = i.data();
				const item = data as vItem;

				item.dateAdded = data.dateAdded.toDate();
				if (data.lastUsed) {
					item.lastUsed = data.lastUsed.toDate();
				}
				return item;
			});
		}
		return [];
	}

	async getQueryUsages(q: Query<DocumentData>) {
		const items = await getDocs(q);
		if (!items.empty) {
			return items.docs.map(u => {
				const data = u.data();
				const usg = data as usage;
				usg.dateUsed = data.dateUsed.toDate();
				return usg;

			});
		}
		return [];
	}

	async createUserCollection() {
		//const item = { uid: userState.uid, id: uuidv4(), name: "test", dateAdded: new Date() };
		await setDoc(doc(collection(this.firestore, `users`), userState.uid), {});

	}

	async addItemImage(item: vItem, img: File, type: ImageTypeEnum) {
		let success = false;

		try {

			if (item && img) {
				const imgId = uuidv4();
				let imgName = imgId;

				switch (type) {
					case ImageTypeEnum.Jpg:
						imgName += ".jpg";
						break;

					case ImageTypeEnum.Gif:
						imgName += ".gif";
						break;

					case ImageTypeEnum.Png:
						imgName += ".png";
						break;
				}

				item.imgName = imgName;


				const imgRef = ref(this.imageFolderRef, item.imgName);


				const snapshot = await uploadBytes(imgRef, img);
				if (snapshot) {
					const downloadUrl = await getDownloadURL(imgRef);
					if (downloadUrl) {
						success = await this.updateItemImage(item, downloadUrl);
						if (success) {
							item.imgUrl = downloadUrl;
						}
					}
				}
			}
		}
		catch (err) {
			return false;
		}
		return success;
	}

	async addUsage(item: vItem, date: Date, imgUrl = "") {
		let success = false;

		const usage = {
			itemId: item.id,
			dateUsed: date,
			id: uuidv4(),
			imgUrl: imgUrl
		} as usage;

		try {




			await setDoc(doc(collection(this.firestore, "users", userState.uid, "usage"), usage.id), usage);

			const itemUses = await this.getAllItemUsages(item.id);

			success = await this.updateItemUses(item.id, itemUses.length, date);

			if (success) {
				item.totalUses = itemUses.length;
				itemsState.updateItemAction(item);
			}

		}
		catch (err) {
			success = false;
		}

		return success ? usage : null;
	}

	async addUsageWithImage(item: vItem, date: Date, img: File, type: ImageTypeEnum): Promise<AddUsageReturnVal> {
		const returnVal = <AddUsageReturnVal>{ success: false, usage: null };

		try {

			if (item && img) {
				const imgId = uuidv4();
				let imgName = imgId;

				switch (type) {
					case ImageTypeEnum.Jpg:
						imgName += ".jpg";
						break;

					case ImageTypeEnum.Gif:
						imgName += ".gif";
						break;

					case ImageTypeEnum.Png:
						imgName += ".png";
						break;
				}

				item.imgName = imgName;


				const imgRef = ref(this.imageFolderRef, item.imgName);


				const snapshot = await uploadBytes(imgRef, img);
				if (snapshot) {
					const downloadUrl = await getDownloadURL(imgRef);
					if (downloadUrl) {
						const addedUsage = await this.addUsage(item, date, downloadUrl);
						returnVal.success = !!addedUsage;
						if (returnVal.success) {
							returnVal.usage = addedUsage;
						}
						/* 						if (success) {
													item.imgUrl = downloadUrl;
												} */
					}
				}
			}
		}
		catch (err) {
			returnVal.success = false;
		}
		return returnVal;
	}

	async addItem(name: string) {
		//const item = { uid: userState.uid, id: uuidv4(), name: "test", dateAdded: new Date() };
		//console.log(item);
		//await addDoc(collection(this.firestore, `items/${userState.uid}`), item);
		const item: vItem = {
			id: uuidv4(), name, dateAdded: new Date(),
			uid: userState.uid, desc: "", lastUsed: new Date(), imgName: "", imgUrl: "", totalUses: 0
		}
		await setDoc(doc(collection(this.firestore, "users", userState.uid, "items"), item.id), item);

		return item;

	}

	async updateItemDetails(item: vItem) {
		let success = false;
		try {
			const itemDoc = await doc(collection(this.firestore, "users", userState.uid, "items"), item.id);
			if (itemDoc) {
				await updateDoc(itemDoc, {
					name: item.name,
					desc: item.desc
				});
				success = true;
			}
		}
		catch (err) {
			success = false;
		}

		return success;
	}

	async updateItemImage(item: vItem, imgurl: string) {
		let success = false;
		try {
			const itemDoc = await doc(collection(this.firestore, "users", userState.uid, "items"), item.id);
			if (itemDoc) {
				await updateDoc(itemDoc, {
					imgName: item.imgName,
					imgUrl: imgurl
				});
				success = true;
			}
		}
		catch (err) {
			success = false;
		}

		return success;
	}

	async updateItemUses(itemId: string, uses: number, lu: Date) {
		let success = false;
		try {
			const itemDoc = await doc(collection(this.firestore, "users", userState.uid, "items"), itemId);

			if (itemDoc) {
				await updateDoc(itemDoc, {
					lastUsed: dayjs(lu).toDate(),
					totalUses: uses
				});
				success = true;
			}
		}
		catch (err) {
			success = false;
		}

		return success;
	}

	async updateItemLU(itemId: string, lu: Date) {
		let success = false;
		try {
			const itemDoc = await doc(collection(this.firestore, "users", userState.uid, "items"), itemId);
			if (itemDoc) {
				await updateDoc(itemDoc, {
					lastUsed: lu
				});
				success = true;
			}
		}
		catch (err) {
			success = false;
		}

		return success;
	}

	async deleteItem(itemId: string) {
		let success = false;
		try {
			const itemDoc = await doc(collection(this.firestore, "users", userState.uid, "items"), itemId);
			if (itemDoc) {
				await deleteDoc(itemDoc);
				success = true;
			}
		}
		catch (err) {
			success = false;
		}

		return success;
	}


	async deleteUsage(item: vItem, usageId: string) {
		let success = false;
		try {
			const usageDoc = await doc(collection(this.firestore, "users", userState.uid, "usage"), usageId);
			if (usageDoc) {
				await deleteDoc(usageDoc);
				success = true;

				const itemUses = await this.getLatestItemUsages(item.id);

				let luDate = typeof (item.dateAdded) === "string" ? new Date(item.dateAdded) : typeof (item.dateAdded) === "object" ? item.dateAdded as Date : new Date();
				if (itemUses.length !== 0) {
					const luTime = Math.max(...itemUses.map(u => u.dateUsed.getTime()));
					luDate = new Date(luTime);
				}
				success = await this.updateItemUses(item.id, itemUses.length, luDate);

				if (success) {
					item.totalUses = itemUses.length;
					itemsState.updateItemAction(item);
				}
			}
		}
		catch (err) {
			success = false;
		}

		return success;
	}


	async getLatestItemUsages(itemId: string) {
		const usageColl = collection(this.firestore, `users/${userState.uid}/usage`);
		const latestItemUsageQ = query(usageColl, where("itemId", "==", itemId), orderBy("dateUsed"), limit(50));

		return await this.getQueryUsages(latestItemUsageQ);
	}

	async getAllItemUsages(itemId: string) {
		const usageColl = collection(this.firestore, `users/${userState.uid}/usage`);
		const latestItemUsageQ = query(usageColl, where("itemId", "==", itemId), orderBy("dateUsed"));

		return await this.getQueryUsages(latestItemUsageQ);
	}

	async getLatestUsages() {
		const usageColl = collection(this.firestore, `users/${userState.uid}/usage`);
		const latestUsageQ = query(usageColl, orderBy("dateUsed"), limit(100));

		return await this.getQueryUsages(latestUsageQ);
	}
}

export default new db();