import usage from "./usage";
import dayjs from "dayjs";

export default class UsageModel {
	data: usage;
	constructor(data?: usage) {
		if (data) {
			this.data = data;
		}
		else {
			this.data = { itemId: '', dateUsed: new Date(), id: '', imgUrl: '' };
		}

	}

	get Id() {
		return this.data.id;
	}

	get ItemId() {
		return this.data.itemId;
	}

	get DateAgoString() {
		return dayjs(this.data.dateUsed).fromNow();
	}

	get DateString() {
		return dayjs(this.data.dateUsed).format("LLL");
	}

	get Image() {
		return this.data.imgUrl;
	}

	get HasImage() {
		return !!this.data.imgUrl;
	}
}