
import vItemModel from "@/data/items/vItemModel";
import { Component, Vue } from "vue-property-decorator";
import { itemsState } from "@/store";
import UsageModel from "@/data/items/usageModel";
import store from "@/data/firebase/store";
import Swal, { SweetAlertOptions } from "sweetalert2";
const reducer = require("image-blob-reduce")();

@Component({})
export default class ItemUsageView extends Vue {
	loading = false;

	usageModel: UsageModel | null = null;

	itemId = this.$route.params.id;
	usageId = this.$route.params.uid;

	get itemModel() {
		const itemIndex = itemsState.items.findIndex(
			(i) => i.id === this.itemId
		);
		if (itemIndex !== -1) {
			return new vItemModel(itemsState.items[itemIndex]);
		}

		return new vItemModel();
	}

	get usageItem() {
		if (this.usageModel) {
			return this.usageModel;
		}
		return new UsageModel();
	}

	async DeleteUsage() {
		let opts: SweetAlertOptions = {
			title: "Are you sure you want to delete this usage?",
			text: `Once you delete an usage, you won't be able to view it again.`,
			showCancelButton: true,
			confirmButtonText: "Yes, delete my usage",
			confirmButtonColor: "#dc2626",
			cancelButtonText: "Keep my usage for now",
			cancelButtonColor: "#64748b",
			focusCancel: true,
		};
		let result = await Swal.fire(opts);
		if (result.isConfirmed) {
			const deleted = await store.deleteUsage(
				this.itemModel.item,
				this.usageItem.Id
			);
			if (deleted) {
				this.$toast.success("Your usage has been successfully deleted");
				if (itemsState.SelectedItem) {
					this.$router.replace(
						`/items/${itemsState.SelectedItem?.id}/view`
					);
				} else {
					this.$router.replace("/");
				}
			} else {
				this.$toast.error("Sorry, we had an issue deleting your usage");
			}
		}
	}

	async mounted() {
		//console.log(`View Item: ${this.$route.params.id}`);
		this.loading = true;

		store
			.getAllItemUsages(this.itemId)
			.then((result) => {
				this.usageModel = new UsageModel(
					result.find((u) => u.id === this.usageId)
				);
			})
			.finally(() => {
				this.loading = false;
			});

		reducer._create_blob = function (env) {
			return this.pica
				.toBlob(env.out_canvas, "image/jpeg", 0.8)
				.then(function (blob) {
					env.out_blob = blob;
					return env;
				});
		};
	}
}
