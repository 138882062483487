import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import Trends from "@/views/Trends.vue";
import ItemView from "@/views/Items/ItemView.vue";
import ItemEdit from "@/views/Items/ItemEdit.vue";
import ItemUsageView from "@/views/Items/ItemUsageView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: "/",
		name: "dashboard",
		component: Dashboard,
	},
	{
		path: "/login",
		name: "login",
		component: Login,
	},
	{
		path: "/register",
		name: "register",
		component: Register,
	},
	{
		path: "/trends",
		name: "trends",
		component: Trends,
	},
	{
		path: "/items/:id/view",
		name: "viewitem",
		component: ItemView
	},
	{
		path: "/items/:id/edit",
		name: "edititem",
		component: ItemEdit
	},
	{
		path: "/items/:id/usages/:uid",
		name: "viewusage",
		component: ItemUsageView
	}

];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
