
import { Component, Vue } from "vue-property-decorator";
import { userState, itemsState } from "@/store";
import store from "@/data/firebase/store";
import vItemModel from "@/data/items/vItemModel";
import Loader from "@/components/Loader.vue";
import router from "@/router";
import {
	ImageTypeEnum,
	ItemLayoutType,
	SortDirection,
	SortType,
} from "@/constants/enums";
import { mixin as clickaway } from "vue-clickaway";
const reducer = require("image-blob-reduce")();

@Component({ mixins: [clickaway], components: { Loader } })
export default class Dashboard extends Vue {
	isMounted = false;
	addingItem = false;
	newItemName = "";

	addingUsage = false;

	sortMenuOpen = false;
	showingSearch = false;
	searchText = "";
	imageFile: File | null = null;

	selectedItemId: string | null = null;

	saving = false;

	get searchTextSnippet() {
		return this.searchText && this.searchText.length > 9
			? this.searchText.substring(6) + "..."
			: this.searchText;
	}

	get useGridView() {
		return itemsState.defaultLayoutType === ItemLayoutType.Grid;
	}

	get useListView() {
		return itemsState.defaultLayoutType === ItemLayoutType.List;
	}

	get items() {
		let theItems = itemsState.items.map((i) => new vItemModel(i));

		if (this.searchText) {
			const searchTextLower = this.searchText.toLowerCase();
			theItems = theItems.filter(
				(i) =>
					i.Desc.toLowerCase().indexOf(searchTextLower) !== -1 ||
					i.Name.toLowerCase().indexOf(searchTextLower) !== -1
			);
		}

		switch (this.sortType) {
			case SortType.Alpha:
				theItems = theItems.sort((a, b) => {
					if (this.sortDir === SortDirection.Asc) {
						return a.Name.toLowerCase() > b.Name.toLowerCase()
							? 1
							: -1;
					} else {
						return a.Name.toLowerCase() > b.Name.toLowerCase()
							? -1
							: 1;
					}
				});
				break;

			case SortType.LastUsed:
				theItems = theItems.sort((a, b) => {
					if (this.sortDir === SortDirection.Asc) {
						return a.LastUsed > b.LastUsed ? 1 : -1;
					} else {
						return a.LastUsed > b.LastUsed ? -1 : 1;
					}
				});
				break;

			case SortType.FreqUsed:
				theItems = theItems.sort((a, b) => {
					if (this.sortDir === SortDirection.Asc) {
						return a.Uses > b.Uses ? 1 : -1;
					} else {
						return a.Uses > b.Uses ? -1 : 1;
					}
				});
				break;
		}

		return theItems;
	}

	get hasNoItems() {
		return (
			this.items.length === 0 && this.isMounted && this.searchText === ""
		);
	}

	get noSearchResults() {
		return (
			this.items.length === 0 && this.isMounted && this.searchText !== ""
		);
	}

	get sortType() {
		return userState.dashSortType;
	}

	get sortDir() {
		return userState.dashSortDir;
	}

	get sortTypeName() {
		switch (this.sortType) {
			case SortType.Alpha:
				return "Name";

			case SortType.LastUsed:
				return "Last Used";

			case SortType.FreqUsed:
				return "Freq Used";

			default:
				return "";
		}
	}

	get sortTypeMobileName() {
		switch (this.sortType) {
			case SortType.Alpha:
				return "Name";

			case SortType.LastUsed:
				return "Used";

			case SortType.FreqUsed:
				return "Freq";

			default:
				return "";
		}
	}

	async FilesChanged(fileName: string, files: File[]) {
		if (files) {
			if (files.length === 1) {
				this.imageFile = files[0];
				if (!this.addingUsage) {
					this.addingUsage = true;
					try {
						let item = itemsState.items.find(
							(i) => i.id === this.selectedItemId
						);
						let errSaving = false;

						if (item && this.imageFile) {
							const newImgBlob = await reducer.toBlob(
								this.imageFile,
								{
									max: 512,
								}
							);

							this.imageFile = new File(
								[newImgBlob],
								this.imageFile.name,
								{
									type: "image/jpeg",
									lastModified: Date.now(),
								}
							);

							const addedUsage = await store.addUsageWithImage(
								item,
								new Date(),
								this.imageFile,
								this.imageFile.type === "image/png"
									? ImageTypeEnum.Png
									: this.imageFile.type === "image/gif"
									? ImageTypeEnum.Gif
									: ImageTypeEnum.Jpg
							);
							errSaving = errSaving || !addedUsage.success;
						}

						if (!errSaving) {
							this.$toast.success(`Added usage!`);
							this.selectedItemId = null;
						}
					} catch (err) {
						//console.error(err);
						this.$toast.error(
							"Sorry, we had an error saving your use :("
						);
					}
					this.addingUsage = false;
				}
			} else {
				this.$toast.error("Please only select one image file.");
			}
		}
	}

	IsSelected(item: vItemModel) {
		return item?.Id === this.selectedItemId;
	}

	OnSelectedClickaway() {
		this.selectedItemId = null;
	}

	ClearSearch() {
		this.searchText = "";
	}

	SearchClick() {
		this.showingSearch = true;
		Vue.nextTick(() => {
			(this.$refs.searchInput as HTMLElement).focus();
		});
	}

	Sort(type: SortType) {
		if (type === this.sortType) {
			if (this.sortDir === SortDirection.Asc) {
				userState.setDashSortDirAction(SortDirection.Desc);
			} else {
				userState.setDashSortDirAction(SortDirection.Asc);
			}
		} else {
			userState.setDashSortTypeAction(type);
		}

		this.sortMenuOpen = false;
		(this.$refs.sortDrop as HTMLElement).blur();
	}

	OnMenuClickaway() {
		this.sortMenuOpen = false;
	}

	OnSearchClickaway() {
		if (this.showingSearch) {
			this.showingSearch = false;
		}
	}

	async AddFirstItem() {
		if (!this.addingItem) {
			try {
				if (this.newItemName.length < 3) {
					this.$toast.warning(
						"Please enter 3 or more letters for your item's name"
					);
					return;
				}
				this.addingItem = true;
				let item = await store.addItem(this.newItemName);
				if (item) {
					//console.log(`adding ${item}`);
					itemsState.addItemAction(item);
					this.newItemName = "";
					this.$toast.success(
						"Congrats on adding your first item to your closet!"
					);
					Vue.nextTick(() => {
						itemsState.setSelectedItemAction(item);
						router.push(`/items/${item.id}/view`);
					});
				} else {
					this.$toast.error(
						"Sorry we had an error adding your item :( - Could not create new item"
					);
				}
			} catch (err) {
				this.$toast.error("Sorry we had an error adding your item :( - Error: " + err);
				//console.error(err);
			} finally {
				this.addingItem = false;
			}
		}
	}

	async AddUsage(item: vItemModel) {
		if (!this.addingUsage) {
			try {
				this.addingUsage = true;
				const now = new Date();
				const usageSuccess = await store.addUsage(item.item, now);
				if (usageSuccess) {
					item.item.lastUsed = now;
					itemsState.updateItemAction(item.item);
					this.$toast.success(`Marked ${item.Name} as used today!`);
					this.selectedItemId = null;
				} else {
					this.$toast.error(
						"Sorry, we had an issue saving your used date."
					);
				}

				this.addingUsage = false;
			} catch (err) {
				this.addingUsage = false;
			}
		}
	}

	async AddUsagePhoto() {
		(this.$refs.fileUploadDash as HTMLInputElement).click();
	}

	ViewItem(item: vItemModel) {
		itemsState.setSelectedItemAction(item.item);
		router.push(`/items/${item.Id}/view`);
	}

	async ItemClick(item: vItemModel) {
		this.selectedItemId = item.Id;
	}

	async mounted() {
		//.log("dashboard");
		if (userState.isLoggedIn) {
			let items = await store.getAllItems();
			itemsState.setItemsAction(items);
		}
		this.isMounted = true;

		reducer._create_blob = function (env) {
			return this.pica
				.toBlob(env.out_canvas, "image/jpeg", 0.8)
				.then(function (blob) {
					env.out_blob = blob;
					return env;
				});
		};
	}
}
