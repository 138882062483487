import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/assets/tailwind.css';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import RelativeTime from "dayjs/plugin/relativeTime";
import VCalendar from "v-calendar";
import VueFeather from 'vue-feather';
import FloatingVue from 'floating-vue';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import 'floating-vue/dist/style.css'

Vue.use(VueFeather);

dayjs.extend(LocalizedFormat);
dayjs.extend(RelativeTime);

import firebase from "@/data/firebase/core";

// Toast options
const options = {
	hideProgressBar: true
};

Vue.use(VCalendar, {});

Vue.use(FloatingVue);

Vue.use(Toast, options);

Vue.config.productionTip = false;

Sentry.init({
	Vue,
	dsn: "https://5e8ebbfb955940df946d98df0f0f0e00@o344670.ingest.sentry.io/6774737",
	integrations: [
		new BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			tracingOrigins: ["localhost", "valoremapp.com", /^\//],
		}),
	],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 0.1,
});

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");


// Setup Routing guards
import "@/router/routeGuards";

// eslint-disable-next-line -- init firebase
const fbApp = firebase;


