import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { SortDirection, SortType } from '@/constants/enums';

@Module({ name: "userState", stateFactory: true })
export default class UserState extends VuexModule {
	email = "";
	isLoggedIn = false;
	uid = "";
	name = "";
	dashSortDir = SortDirection.Desc;
	dashSortType = SortType.LastUsed;


	@Mutation
	setDashSortDir(dir: SortDirection) {
		this.dashSortDir = dir;
	}

	@Mutation
	setDashSortType(type: SortType) {
		this.dashSortType = type;
	}

	@Mutation
	setName(name: string) {
		this.name = name;
	}

	@Mutation
	setEmail(email: string) {
		this.email = email;
	}

	@Mutation
	setUid(uid: string) {
		this.uid = uid;
	}

	@Mutation
	setIsLoggedIn(isLoggedIn: boolean) {
		this.isLoggedIn = isLoggedIn;
		if (!this.isLoggedIn) {
			this.uid = "";
			this.name = "";
		}
	}

	@Action({ commit: 'setDashSortDir' })
	setDashSortDirAction(dir: SortDirection) {
		return dir;
	}

	@Action({ commit: 'setDashSortType' })
	setDashSortTypeAction(type: SortType) {
		return type;
	}

	@Action({ commit: 'setEmail' })
	setEmailAction(email: string) {
		return email;
	}

	@Action({ commit: 'setName' })
	setNameAction(name: string) {
		return name;
	}

	@Action({ commit: 'setUid' })
	setUidAction(uid: string) {
		return uid;
	}

	@Action({ commit: 'setIsLoggedIn' })
	setIsLoggedInAction(isLoggedIn: boolean) {
		return isLoggedIn
	}

}