
import { Component, Vue } from "vue-property-decorator";
import {
	getAuth,
	createUserWithEmailAndPassword,
	updateProfile,
} from "firebase/auth";
import { userState } from "@/store";

@Component({})
export default class Login extends Vue {
	email = "";
	password = "";
	name = "";
	termsAgreed = false;

	showInvalidEmailErr = false;
	showEmailTakenErr = false;
	showWeakPwErr = false;
	showMiscRegisterErr = false;

	registering = false;

	get showError() {
		return (
			this.showInvalidEmailErr ||
			this.showEmailTakenErr ||
			this.showWeakPwErr ||
			this.showMiscRegisterErr
		);
	}

	createUser() {
		if (
			this.email &&
			this.password &&
			this.termsAgreed &&
			!this.registering
		) {
			this.registering = true;
			this.showInvalidEmailErr = false;
			this.showEmailTakenErr = false;
			this.showWeakPwErr = false;
			this.showMiscRegisterErr = false;

			const auth = getAuth();
			createUserWithEmailAndPassword(auth, this.email, this.password)
				.then(async (userCredential) => {
					// Signed in
					const user = userCredential.user;
					userState.setIsLoggedInAction(true);
					userState.setEmailAction(user.email ?? "");
					userState.setUidAction(user.uid);

					//await fireStore.createUserCollection();

					Vue.nextTick(() => {
						this.$router.push("/");
					});

					if (this.name) {
						updateProfile(user, { displayName: this.name }).then(
							() => {
								userState.setNameAction(this.name);
							}
						);
					}

					// ...
				})
				.catch((error) => {
					const errorCode = error.code;
					switch (errorCode) {
						case "auth/email-already-in-use":
							this.showEmailTakenErr = true;
							break;

						case "auth/invalid-email":
							this.showInvalidEmailErr = true;
							break;

						case "auth/weak-password":
							this.showWeakPwErr = true;
							break;

						default:
							this.showMiscRegisterErr = true;
							break;
					}
				})
				.finally(() => {
					this.registering = false;
				});
		}
	}

	//mounted() {}
}
