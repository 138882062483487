
import { Component, Vue } from "vue-property-decorator";
import {
	signInWithEmailAndPassword,
	setPersistence,
	browserLocalPersistence,
	sendPasswordResetEmail,
} from "firebase/auth";
import { userState } from "@/store";
import analytics from "@/data/firebase/analytics";
import EventNames from "@/constants/eventNames";
import Swal, { SweetAlertOptions } from "sweetalert2";
import auth from "@/data/firebase/auth";
import router from "@/router";

@Component({})
export default class Login extends Vue {
	email = userState.email;
	password = "";
	showNoUserError = false;
	showMiscError = false;
	showInvalidEmailPw = false;
	showTooManyReqsError = false;

	loggingIn = false;

	get showError() {
		return (
			this.showNoUserError ||
			this.showMiscError ||
			this.showInvalidEmailPw ||
			this.showTooManyReqsError
		);
	}

	login() {
		if (this.email && this.password && !this.loggingIn) {
			this.loggingIn = true;
			this.showNoUserError = false;
			this.showMiscError = false;
			this.showInvalidEmailPw = false;
			this.showTooManyReqsError = false;
			userState.setEmailAction(this.email);

			setPersistence(auth, browserLocalPersistence);
			signInWithEmailAndPassword(auth, this.email, this.password)
				.then((userCredential) => {
					// Signed in
					userState.setIsLoggedInAction(true);
					analytics.logEvent(EventNames.Login);
					const user = userCredential.user;
					userState.setUidAction(user.uid);
					userState.setEmail(user.email ?? this.email);
					userState.setName(user.displayName ?? "");
					Vue.nextTick(() => {
						router.push("/");
					});

					// ...
				})
				.catch((error) => {
					if (error.code === "auth/user-not-found") {
						this.showNoUserError = true;
					} else if (
						error.code === "auth/invalid-email" ||
						error.code === "auth/wrong-password"
					) {
						this.showInvalidEmailPw = true;
					} else if (error.code === "auth/too-many-requests") {
						this.showTooManyReqsError = true;
					} else {
						this.showMiscError = true;
					}
					analytics.logEvent(EventNames.LoginError, "", error);
				})
				.finally(() => {
					this.loggingIn = false;
				});
		}
	}

	showPasswordReset() {
		if (this.email) {
			let opts: SweetAlertOptions = {
				title: "Reset Password",
				text: `Send a password reset email to ${this.email}?`,
				icon: "question",
				showCancelButton: true,
			};
			Swal.fire(opts).then((result) => {
				if (result.isConfirmed) {
					sendPasswordResetEmail(auth, this.email)
						.then(() => {
							this.$toast.success("Password reset email sent");
						})
						.catch(() => {
							this.$toast.error(
								"Sorry we had an issue sending the password reset email."
							);
						});
				}
			});
		}
	}
}
