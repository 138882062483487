
import { Component, Vue } from "vue-property-decorator";
import { userState, itemsState } from "@/store";
import { getAuth, signOut } from "firebase/auth";
import router from "@/router";
import store from "@/data/firebase/store";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { mixin as clickaway } from "vue-clickaway";

@Component({ mixins: [clickaway] })
export default class App extends Vue {
	profileOpen = false;
	mobileMenuOpen = false;

	get loggedIn() {
		return userState.isLoggedIn;
	}

	get email() {
		return userState.email;
	}

	get name() {
		return userState.name;
	}

	get onTrends() {
		return this.$route.name === "trends";
	}

	get onDashboard() {
		return this.$route.name === "dashboard";
	}

	OnProfileClickaway() {
		this.profileOpen = false;
	}

	async getItems() {
		router.push("/");
	}

	async AddItem() {
		let opts: SweetAlertOptions = {
			title: "Add an Item",
			text: `Enter the name you'd like to use for this item.`,
			showCancelButton: true,
			confirmButtonColor: "#65C3C8",
			input: "text",
			inputPlaceholder: "rolex speedmaster",
			inputValidator: (value) => {
				if (!value) {
					return "Please enter a name for your item!";
				}
				return null;
			},
		};
		let result = await Swal.fire(opts);
		if (result.value) {
			const item = await store.addItem(result.value);
			if (item) {
				itemsState.addItemAction(item);

				Vue.nextTick(() => {
					itemsState.setSelectedItemAction(item);
					//console.log(`added ${item}`);
					router.push(`/items/${item.id}/edit`);
				});
			} else {
				this.$toast.error("Sorry we had an error adding your item :(");
			}
		}
	}

	NavToTrends() {
		router.push("/trends");
	}

	SignOut() {
		this.profileOpen = false;
		this.mobileMenuOpen = false;
		const auth = getAuth();
		signOut(auth).finally(() => {
			userState.setIsLoggedInAction(false);
			Vue.nextTick(() => {
				router.replace("/login");
			});
		});
	}
}
