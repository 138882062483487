import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from 'vuex-persist';
import {
	getModule
} from "vuex-module-decorators";

import UserState from "./user";
import ItemsState from "./item";

const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
});

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		userState: UserState,
		itemsState: ItemsState
	},
	plugins: [vuexLocal.plugin]
});

export default store;
export const userState: UserState = getModule(UserState, store);
export const itemsState: ItemsState = getModule(ItemsState, store);