
import vItemModel from "@/data/items/vItemModel";
import { Component, Vue } from "vue-property-decorator";
import { itemsState } from "@/store";
import UsageModel from "@/data/items/usageModel";
import store from "@/data/firebase/store";
import ItemCalendar from "@/components/ItemCalendar.vue";
import { v4 as uuidv4 } from "uuid";
import Swal, { SweetAlertOptions } from "sweetalert2";

@Component({ components: { ItemCalendar } })
export default class ItemView extends Vue {
	usagesList: Array<UsageModel> = [];

	adding = false;

	get itemModel() {
		if (itemsState.SelectedItem) {
			return new vItemModel(itemsState.SelectedItem);
		}
		return new vItemModel();
	}

	async DeleteItem() {
		let opts: SweetAlertOptions = {
			title: "Are you sure you want to delete this item?",
			text: `Once you delete an item, you won't be able to see any data on it again.`,
			showCancelButton: true,
			confirmButtonText: "Yes, delete my item",
			confirmButtonColor: "#dc2626",
			cancelButtonText: "Keep my item for now",
			cancelButtonColor: "#64748b",
			focusCancel: true,
		};
		let result = await Swal.fire(opts);
		if (result.isConfirmed) {
			const deleted = await store.deleteItem(this.itemModel.Id);
			if (deleted) {
				this.$toast.success("Your item has been successfully deleted");
				itemsState.removeItemAction(this.itemModel.item);
				this.$router.replace("/");
			} else {
				this.$toast.error("Sorry, we had an issue deleting your item");
			}
		}
	}

	EditItem() {
		this.$router.push(`/items/${this.itemModel.Id}/edit`);
	}

	async AddUsage() {
		if (!this.adding) {
			this.adding = true;
			try {
				const success = await store.addUsage(
					this.itemModel.item,
					new Date()
				);
				if (success) {
					this.usagesList.splice(
						0,
						0,
						new UsageModel({
							itemId: this.itemModel.Id,
							id: uuidv4(),
							dateUsed: new Date(),
							imgUrl: "",
						})
					);
				}
			} catch (err) {
				//console.error(err);
			}
			this.adding = false;
		}
	}

	async AddUsagePhoto() {
		if (!this.adding) {
			this.adding = true;
			try {
				const success = await store.addUsage(
					this.itemModel.item,
					new Date()
				);
				if (success) {
					this.usagesList.splice(
						0,
						0,
						new UsageModel({
							itemId: this.itemModel.Id,
							id: uuidv4(),
							dateUsed: new Date(),
							imgUrl: "",
						})
					);
				}
			} catch (err) {
				//console.error(err);
			}
			this.adding = false;
		}
	}

	async mounted() {
		//console.log(`View Item: ${this.$route.params.id}`);

		if (this.itemModel) {
			const usages = await store.getAllItemUsages(this.itemModel.Id);
			if (usages) {
				this.usagesList = usages
					.map((u) => new UsageModel(u))
					.sort((a, b) => {
						if (a && !b) {
							return -1;
						} else if (!a && b) {
							return 1;
						} else {
							if (a.data.dateUsed > b.data.dateUsed) {
								return -1;
							} else {
								return 1;
							}
						}
					});

				if (!this.itemModel.Uses) {
					store.updateItemUses(
						this.itemModel.Id,
						this.usagesList.length,
						this.itemModel.LastUsed
					);
				}
			}
		}
	}
}
